import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-dc-gmcmauhjd0gmeqd2.eastus2-01.azurewebsites.net/api/v1/",
  googleMapskey: "AIzaSyDaXyX_LvkVMSM0xId6uJIwqfu-Euw-gYA",
  sentryDSN: "https://b3a70158ac9d56f28a31ddbcc6329254@o4505172191608832.ingest.us.sentry.io/4507925125398528",
  appTitle: (title: string) => `${title ? `${title} | ` : ""}Duracreto - Panel Administrativo`,
};
