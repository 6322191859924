import { FuseNavigationItem } from "@fuse/components/navigation";

export default function CommunityManagerNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "crm.dashboard",
          title: "Dashboard Mercadeo",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard/merch",
        },
        {
          id: "crm.clientes",
          title: "Clientes",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/crm/customers",
        },
        {
          id: "usersProspects",
          title: "Leads",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/prospects",
        },

        {
          id: "crm.messages",
          title: "Mensajes",
          type: "basic",
          icon: "heroicons_outline:inbox-in",
          link: "/crm/messages",
        },
        {
          id: "crm.newsletter",
          title: "Suscriptores a boletín",
          type: "basic",
          icon: "heroicons_outline:inbox-out",
          link: "/crm/newsletter",
        },
        // {
        //     id: 'crm.financePlan',
        //     title: 'Planes de Financiamiento',
        //     type: 'basic',
        //     icon: 'heroicons_outline:document-report',
        //     link: '/crm/financePlan'
        // },
        // {
        //   id: "crm.adCampaign",
        //   title: "Campañas Publicitarias",
        //   type: "basic",
        //   icon: "heroicons_outline:megaphone",
        //   link: "/crm/adCampaign",
        // },
      ],
    },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los productos",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "inventory.products",
          title: "Productos",
          type: "basic",
          icon: "heroicons_outline:shopping-bag",
          link: "/inventory/products",
        },
      ],
    },
  ];

  return nav;
}
