import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HeadersHttpService {
  public getHeadersPublic() {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }
  public getHeadersPrivate(
    payload: { showModal: boolean } = { showModal: true }
  ) {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${atob(localStorage.getItem("accessToken"))}`,
      showModal: payload.showModal ? "yes" : "no",
    });
  }
  public getHeadersFormDataPrivate() {
    return new HttpHeaders({
      "Content-Disposition": "multipart/form-data",
      Authorization: `Bearer ${atob(localStorage.getItem("accessToken"))}`,
    });
  }

  public getHeadersTextPlainPrivate() {
    return new HttpHeaders({
      "Content-Type": "text/plain; charset=utf-8",
      Authorization: `Bearer ${atob(localStorage.getItem("accessToken"))}`,
    });
  }
}
