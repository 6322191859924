import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';

import { AlertService } from '#utils/alert-service/alert-service.service';
import { AppVersionService } from './shared/services/version';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	/**
	 * Constructor
	 */
	constructor(private alertService: AlertService, private matDialog: MatDialog, private _appVersionService: AppVersionService) {
		this.changelog();

		if (localStorage.getItem('idUser')) {
			// Save user Sentry
			Sentry.setUser({ id: localStorage.getItem('idUser'), email: localStorage.getItem('email'), username: localStorage.getItem('fullName') });
		}

	}

	errorMessage$: Observable<{ message: string; title?: string; errors: Array<string> | null }> = this.alertService.message$;
	updateMessage$: Observable<string> = this.alertService.updateMessage$;
	saveMessage$: Observable<string> = this.alertService.saveMessage$;

	public async changelog() {
		/* if (await this._appVersionService.outdated()) {
            this.matDialog.open(VersionChangelogComponent, {
                width: '100%',
                maxWidth: '600px',
                maxHeight: '90vh',
                disableClose: true,
            })
        } */
	}
}
