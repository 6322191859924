import AdminNav from "#utils/nav-role/nav-admin";
import CommunityManagerNav from "#utils/nav-role/nav-community-manager";
import CreditManagerNav from "#utils/nav-role/nav-credit-manager";
import ManagerNav from "#utils/nav-role/nav-manager";
import OfficeSalesAdvisorNav from "#utils/nav-role/nav-office-sales-advisor";
import SalesAdvisorNav from "#utils/nav-role/nav-sales-advisor";
/* tslint:disable:max-line-length */
import SuperAdminNav from "#utils/nav-role/nav-super-admin";

export default function defaultNavigation() {
  const isSuperAdmin = localStorage.getItem("rol") == "SuperAdmin";
  const isAfterSale = localStorage.getItem("rol") == "AfterSale";
  const isManager = localStorage.getItem("rol") == "Manager";
  const isCommunityManager = localStorage.getItem("rol") == "CommunityManager";
  const isSalesAdvisor = localStorage.getItem("rol") == "SalesAdvisor";
  const isAdmin = localStorage.getItem("rol") == "Admin";
  const isCreditManager = localStorage.getItem("rol") == "CreditManager";
  const isOfficeSalesAdvisor =
    localStorage.getItem("rol") == "OfficeSalesAdvisor";
  if (isSuperAdmin) {
    return SuperAdminNav();
  }
  // if (isAfterSale) {
  //     return AfterSaleNav();
  // }

  if (isSalesAdvisor) {
    return SalesAdvisorNav();
  }

  if (isManager) {
    return ManagerNav();
  }

  if (isCommunityManager) {
    return CommunityManagerNav();
  }

  if (isAdmin) {
    return AdminNav();
  }

  if (isCreditManager) {
    return CreditManagerNav();
  }

  if (isOfficeSalesAdvisor) {
    return OfficeSalesAdvisorNav();
  }
}
